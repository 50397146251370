import React, { useState } from 'react'
import "./qualification.css"

const Qualification = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }
  return (
    <section className="qualification section" id="qualification">
        <h2 className="section__title">Class Schedule</h2>
        <span className="section__subtitle">Sunday - Friday</span>
        <p className="click_on">Click on <span className="click_day">Day</span> for schedule</p>
        <div className="qualification__container container">
            <div className="qualification__tabs">
                <div className={toggleState === 1 ? "qualification__button qualification__active button--flex" : 
            "qualification__button button--flex"} onClick={() => toggleTab(1)}>
                    
                    Sunday
                </div>

                <div className={toggleState === 2 ? "qualification__button qualification__active button--flex" : 
            "qualification__button button--flex"} onClick={() => toggleTab(2)}>
                    
                    Monday
                </div>

                <div className={toggleState === 3 ? "qualification__button qualification__active button--flex" : 
            "qualification__button button--flex"} onClick={() => toggleTab(3)}>
                    
                    Tuesday
                </div>

                <div className={toggleState === 4 ? "qualification__button qualification__active button--flex" : 
            "qualification__button button--flex"} onClick={() => toggleTab(4)}>
                    
                    Wednesday
                </div>

                <div className={toggleState === 5 ? "qualification__button qualification__active button--flex" : 
            "qualification__button button--flex"} onClick={() => toggleTab(5)}>
                    
                    Thursday
                </div>

                <div className={toggleState === 6 ? "qualification__button qualification__active button--flex" : 
            "qualification__button button--flex"} onClick={() => toggleTab(6)}>
                    
                    Friday
                </div>
            </div>

            <div className="qualification__sections">
                {/* Sunday */}
                <div className={toggleState === 1 ? "qualification__content qualification__content-active" : 
            "qualification__content"}>
                    <div className="qualification__data">
                        <div>
                            <h3 className="qualification__title_left">Open Mat</h3>
                            <span className="qualification__subtitle_left">2:00 PM</span>
                        </div>
                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>
                </div>

                {/* Monday */}
                <div className={toggleState === 2 ? "qualification__content qualification__content-active" : 
            "qualification__content"}>
                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                        <h3 className="qualification__title_left">Brazilian Jiu Jitsu</h3>
                            <span className="qualification__subtitle_left">6:00 PM - 7:30 PM</span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div>
                        <h3 className="qualification__title">Adult BJJ - No Gi</h3>
                            <span className="qualification__subtitle"></span>
                        </div>
                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                        <h3 className="qualification__title_left">Mixed Martial Arts</h3>
                            <span className="qualification__subtitle_left">7:30 PM - 8:30 PM</span>
                            
                        </div>
                    </div>
                </div>
                
                {/* Tuesday */}
                <div className={toggleState === 3 ? "qualification__content qualification__content-active" : 
            "qualification__content"}>
                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                        <h3 className="qualification__title_left">Brazilian Jiu Jitsu</h3>
                            <span className="qualification__subtitle_left">6:00 PM - 7:30 PM</span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div>
                        <h3 className="qualification__title">Adult BJJ - Gi</h3>
                            <span className="qualification__subtitle"></span>
                        </div>
                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                        <h3 className="qualification__title_left">Mixed Martial Arts</h3>
                            <span className="qualification__subtitle_left">7:30 PM - 8:30 PM</span>
                            
                        </div>
                    </div>
                </div>
                
                {/* Wednesday */}
                <div className={toggleState === 4 ? "qualification__content qualification__content-active" : 
            "qualification__content"}>
                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                        <h3 className="qualification__title_left">Brazilian Jiu Jitsu</h3>
                            <span className="qualification__subtitle_left">6:00 PM - 7:30 PM</span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div>
                        <h3 className="qualification__title">Adult BJJ - No Gi</h3>
                            <span className="qualification__subtitle"></span>
                        </div>
                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                        <h3 className="qualification__title_left">Mixed Martial Arts</h3>
                            <span className="qualification__subtitle_left">7:30 PM - 8:30 PM</span>
                            
                        </div>
                    </div>
                </div>

                {/* Thursday */}
                <div className={toggleState === 5 ? "qualification__content qualification__content-active" : 
            "qualification__content"}>
                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                        <h3 className="qualification__title_left">Brazilian Jiu Jitsu</h3>
                            <span className="qualification__subtitle_left">6:00 PM - 7:30 PM</span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div>
                        <h3 className="qualification__title">Adult BJJ - Gi</h3>
                            <span className="qualification__subtitle"></span>
                        </div>
                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                        <h3 className="qualification__title_left">Mixed Martial Arts</h3>
                            <span className="qualification__subtitle_left">7:30 PM - 8:30 PM</span>
                            
                        </div>
                    </div>
                </div>

                {/* Friday */}
                <div className={toggleState === 6 ? "qualification__content qualification__content-active" : 
            "qualification__content"}>
                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                        <h3 className="qualification__title_left">Brazilian Jiu Jitsu</h3>
                            <span className="qualification__subtitle_left">6:00 PM - 7:30 PM</span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div>
                        <h3 className="qualification__title">Adult BJJ - No Gi Fundamentals</h3>
                            <span className="qualification__subtitle"></span>
                        </div>
                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h1 className="bottom_text"><span className="bottom_text_red">Announcement: </span>Kids classes will overlap with adults; Inquire for more info</h1>
    </section>
  )
}

export default Qualification